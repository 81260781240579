import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { Loader } from "@googlemaps/js-api-loader";
import silverMap from "./map.json";
import smilerSVG from "../assets/smiler.svg";
import clueMarkerSVG from "../assets/clueMarker.svg";
import { ClueMarker, Marker, Position } from "./types";
import { delay, getMarker } from "./utils";

type MarkerType = any;

type Action =
  | { type: "createMarker" }
  | { type: "initMap"; map: google.maps.Map }
  | { type: "updateUserPosition"; userLocation: Position }
  | { type: "updateMarkers"; markerType: MarkerType; markers: Array<Marker> }
  | {
      type: "collectIconSelectors";
      iconType: string;
      icons: Array<HTMLElement>;
    };

type Dispatch = (action: Action) => void;

type State = {
  loading: boolean;
  markers: Record<MarkerType, Array<Marker>>;
  icons: Record<string, Array<HTMLElement> | HTMLElement | undefined>;
  map: google.maps.Map | undefined;
  mapContainer: HTMLDivElement | undefined;
  userLocation: Position;
};

const initialState = {
  loading: true,
  markers: { cartons: [], users: [] },
  icons: { cartons: [], user: undefined },
  map: undefined,
  mapContainer: undefined,
  userLocation: { lat: undefined, lng: undefined },
};

const MapContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "updateMarkers":
      return {
        ...state,
        markers: { ...state.markers, [action.markerType]: action.markers },
      };
    case "initMap":
      return { ...state, map: action.map, loading: false };
    case "updateUserPosition":
      return { ...state, userLocation: action.userLocation };
    default:
      return state;
  }
};

const loader = new Loader({
  apiKey: process.env.REACT_APP_GMAP_KEY as string,
  version: "weekly",
});

const DEFAULT_LAT = 34.09814069282424;
const DEFAULT_LNG = -118.26501333700854;

const MapProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = { state, dispatch };
  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};

export { MapContext, MapProvider };

export const useMap = () => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const context = useContext(MapContext);

  if (context === undefined) {
    throw new Error("must be within its provider: Map");
  }

  const { dispatch, state } = context;
  const initMap = useCallback(() => {
    loader.load().then(() => {
      if (!mapContainer.current) {
        return console.error("map container missing");
      }
      const map = new google.maps.Map(mapContainer.current, {
        zoom: 15,
        styles: silverMap,
        center: {
          lat: DEFAULT_LAT,
          lng: DEFAULT_LNG,
        },
        disableDefaultUI: true,
      });
      dispatch({ type: "initMap", map });

      map.addListener("click", (mapsMouseEvent: any) => {
        console.log(mapsMouseEvent.latLng.toJSON());
      });
    });
  }, [dispatch]);

  return { mapContainer, initMap, loading: state.loading };
};

export const useUserLocation = () => {
  const context = useContext(MapContext);

  if (context === undefined) {
    throw new Error("Map Context error in UserLocation hook");
  }
  const { dispatch, state } = context;

  const createUserMarker = async (lat: number, lng: number) => {
    const position = { lat, lng };
    const userMarker = new google.maps.Marker({
      position: position,
      icon: smilerSVG,
      map: state.map,
      optimized: false,
    });
    dispatch({
      type: "updateMarkers",
      markerType: "users",
      markers: [{ marker: userMarker, type: "user" }],
    });

    const markerDom = await getMarker(`img[src='${smilerSVG}']`);
    delay(100, () => {
      markerDom.classList.add("marker-pulse");
      dispatch({
        type: "collectIconSelectors",
        iconType: "user",
        icons: [markerDom],
      });
    });
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const userLoc = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          dispatch({ type: "updateUserPosition", userLocation: userLoc });
          if (!state.userLocation.lat && state.map) {
            // state.map.panTo(userLoc as google.maps.LatLngLiteral);
            createUserMarker(userLoc.lat, userLoc.lng);
          }
        }
      );
    }
  };

  const recenter = () => {
    state.map &&
      state.map.panTo(state.userLocation as google.maps.LatLngLiteral);
  };

  useEffect(() => {
    if (state.map && state.markers.users[0]) {
      state.markers.users[0].marker.setPosition(
        state.userLocation as google.maps.LatLngLiteral
      );
    }
  }, [state.userLocation, state.map, state.markers.users]);

  return {
    getUserLocation,
    userLocation: state.userLocation,
    createUserMarker,
    recenter,
  };
};

export const useMarkers = () => {
  const context = useContext(MapContext);

  if (context === undefined) {
    throw new Error("Map Context missing in UseMarkers hook");
  }

  const { dispatch, state } = context;

  const addMarkers = useCallback(
    (markers: ClueMarker[]) => {
      const newMarkers = markers.map((marker: ClueMarker) => {
        const position = {
          lat: marker.position.lat,
          lng: marker.position.lng,
        } as google.maps.LatLngLiteral;
        const clueMarker = new google.maps.Marker({
          position,
          icon: clueMarkerSVG,
          map: state.map,
        });
        state.map!.panTo(position);
        return { type: "clue", marker: clueMarker, clueId: marker.clueId };
      });
      dispatch({
        type: "updateMarkers",
        markers: newMarkers,
        markerType: "clue",
      });
    },
    [dispatch, state.map]
  );

  const hideMarker = (clueId: number) => {
    const marker = state.markers.clue.find(
      (marker: any) => marker.clueId === clueId
    );
    if (marker) {
      marker.marker.setMap(null);
    }
  };
  return { addMarkers, hideMarker, loading: state.loading };
};
