export default function getSelectedCellElements() {
  const cells = document.getElementsByClassName("cell");
  const selectedCells: HTMLDivElement[] = [];
  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];
    if (cell.classList.contains("selected")) {
      selectedCells.push(cell as HTMLDivElement);
    }
  }
  return selectedCells;
}
