import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import firebaseConfig from "./firebase-config.json";

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();

if (process.env.NODE_ENV === "development") {
  firebase.functions().useEmulator("localhost", 5001);
  db.useEmulator("localhost", 8080);
}

export const checkCode = firebase.functions().httpsCallable("checkCode");
export const getCluePosition = firebase
  .functions()
  .httpsCallable("getCluePosition");

export const checkWin = firebase.functions().httpsCallable("checkWin");

export const getHuntData = async (huntId: string) => {
  const hunt = await db.collection("HUNTS").doc(huntId).get();
  const { numberOfClues, id, completed } = hunt.data() as any;
  return { numberOfClues, id, completed };
};
