import { useEffect, useRef, useState } from "react";
import "./App.css";
import Smiler from "./components/Smiler";
import Map from "./containers/Map";
import { useMap, useUserLocation } from "./contexts/MapContext";
import { useGame, useGrid } from "./contexts/PuzzleContext";
import { Cell, Feedback } from "./contexts/types";
import { countSelected } from "./contexts/utils";
import { cellSelectedFeedback } from "./selectors/cellAnimations";
import getSelectedCellElements from "./selectors/getSelectedCellElements";

function App() {
  const { cells, toggleCell } = useGrid();
  const {
    clueId,
    feedback,
    progress,
    clearCells,
    hideMarker,
    numberOfClues,
    completed,
  } = useGame();
  const { getUserLocation } = useUserLocation();
  const { loading } = useMap();

  const [cellHeight, setCellHeight] = useState(0);
  const cellRef = useRef<HTMLDivElement | null>(null);

  const numSelected = cells.reduce(countSelected, 0);

  useEffect(() => {
    const cell = cellRef.current;
    if (!cell) {
      return;
    }
    const { width } = cell.getBoundingClientRect();
    setCellHeight(width);
  }, [cellRef, cells]);

  useEffect(() => {
    if (numSelected < 4) {
      return;
    }
    const selectedCells = getSelectedCellElements();
    const promises = cellSelectedFeedback(feedback, selectedCells);
    Promise.all(promises).then(() => {
      clearCells();
      if (feedback === Feedback.Right && clueId < numberOfClues) {
        alert("Nice! Look at the map for the next clue!");
        progress();
        hideMarker(clueId);
      }
    });
  }, [
    feedback,
    progress,
    clearCells,
    clueId,
    numberOfClues,
    hideMarker,
    numSelected,
  ]);

  useEffect(() => {
    if (loading) {
      return;
    }
    const interval = setInterval(getUserLocation, 2000);
    return () => clearInterval(interval);
  }, [loading, getUserLocation]);

  let completedMessage = "";
  if (completed === true) {
    completedMessage = "TIX HAVE BEEN CLAIMED";
  } else if (completed === false) {
    completedMessage = "TIX STILL AVAILABLE";
  }

  return (
    <div className="App">
      <div className="header">
        <div style={{ color: completed ? "red" : "#56ff56" }}>
          {completedMessage}
        </div>
      </div>
      <div className="container">
        <div className="grid-container">
          {cells.map((cell: Cell) => {
            const id = `cell-${cell.row}-${cell.column}`;
            return (
              <div
                onClick={() =>
                  numSelected < 4 && toggleCell(cell.row, cell.column)
                }
                key={id}
                id={id}
                className={`cell ${cell.selected ? "selected" : ""}`}
                style={{ height: cellHeight }}
                ref={cellRef}
              ></div>
            );
          })}
          <div
            className={`smiler-container ${
              feedback !== Feedback.Idle ? "show pulse" : ""
            }`}
          >
            <Smiler feedback={feedback} />
          </div>
        </div>

        <Map />
      </div>
    </div>
  );
}

export default App;
