import { Feedback } from "../contexts/types";

const feedbackMap = {
  [Feedback.Idle]: "",
  [Feedback.Fetching]: "white",
  [Feedback.Right]: "green",
  [Feedback.Wrong]: "red",
};

export default function Smiler({ feedback }: { feedback: Feedback }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 618.58 569.76"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <circle
            className={`smiler-eye-left ${feedbackMap[feedback]}`}
            cx="159.44"
            cy="257.2"
            r="44.15"
            fill="#6964ff"
          />
          <circle
            className={`smiler-eye-right ${feedbackMap[feedback]}`}
            cx="448.4"
            cy="257.2"
            r="44.15"
            fill="#6964ff"
          />
          <path
            className={`smiler-mouth ${feedbackMap[feedback]}`}
            d="M121.39,360.12s45.32,132.4,187.44,132.4,185.5-133.4,185.5-133.4"
            fill="none"
            stroke="#6964ff"
            strokeMiterlimit="10"
            strokeWidth="26"
          />
          <ellipse
            className={`smiler-head ${feedbackMap[feedback]}`}
            cx="309.29"
            cy="284.88"
            rx="296.29"
            ry="271.88"
            fill="none"
            stroke="#6964ff"
            strokeMiterlimit="10"
            strokeWidth="26"
          />
        </g>
      </g>
    </svg>
  );
}
