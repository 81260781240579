import { Feedback } from "../contexts/types";

export const cellAnimate = (cell: HTMLElement, animName: string) => {
  return new Promise((resolve) => {
    cell.classList.add(animName);
    cell.onanimationend = () => {
      cell.classList.remove(animName);
      resolve(true);
    };
  });
};

export const cellSelectedFeedback = (
  feedback: Feedback,
  selectedCells: HTMLDivElement[]
) => {
  let promises: Array<Promise<unknown>> = [];
  selectedCells.forEach((cell: HTMLDivElement) => {
    promises.push(cellAnimate(cell, feedback));
  });
  return promises;
};
