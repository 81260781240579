import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import {
  checkCode,
  checkWin,
  getCluePosition,
  getHuntData,
} from "../services/firebase";
import { useMarkers } from "./MapContext";
import { Cell, Feedback } from "./types";
import { countSelected } from "./utils";

const HUNT_ID = "1";

type Action =
  | { type: "INIT_GRID"; cells: Cell[] }
  | { type: "INIT_HUNT"; numberOfClues: number; completed: boolean }
  | { type: "TOGGLE_CELL"; row: number; column: number }
  | { type: "CLEAR_CELLS" }
  | { type: "PROGRESS" }
  | { type: "SET_FEEDBACK"; feedback: Feedback }
  | { type: "ADD_SOLUTION"; solution: number[] };

type Dispatch = (action: Action) => void;

type State = {
  cells: Cell[];
  huntId: string;
  numberOfClues: number;
  clueId: number;
  feedback: Feedback;
  solutions: number[][];
  init: boolean;
  completed: boolean | undefined;
};

const initialState = {
  cells: [],
  huntId: HUNT_ID,
  numberOfClues: 0,
  clueId: 1,
  feedback: Feedback.Idle,
  solutions: [],
  init: false,
  completed: undefined,
};

const PuzzleContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

localStorage.setItem("clueId", "");
localStorage.setItem("solutions", "");

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "INIT_GRID":
      return { ...state, cells: action.cells };
    case "INIT_HUNT":
      let initSolutions: number[][] = [];
      // const savedSolutions = localStorage.getItem("solutions");
      // if (savedSolutions) {
      //   initSolutions = JSON.parse(savedSolutions);
      // }
      let initClueId = 1;
      // const savedClueId = localStorage.getItem("clueId");
      // if (savedClueId) {
      //   initClueId = parseInt(savedClueId);
      // }
      return {
        ...state,
        numberOfClues: action.numberOfClues,
        solutions: initSolutions,
        clueId: initClueId,
        init: true,
        completed: action.completed,
      };
    case "TOGGLE_CELL":
      const cells = state.cells.map((cell: Cell) => {
        if (cell.row === action.row && cell.column === action.column) {
          cell = { ...cell, selected: !cell.selected };
        }
        return cell;
      });
      return { ...state, cells };
    case "CLEAR_CELLS":
      return {
        ...state,
        cells: state.cells.map((cell: Cell) => ({ ...cell, selected: false })),
        feedback: Feedback.Idle,
      };
    case "SET_FEEDBACK":
      return { ...state, feedback: action.feedback };
    case "PROGRESS":
      const clueId = state.clueId + 1;
      // localStorage.setItem("clueId", clueId.toString());
      return { ...state, clueId };
    case "ADD_SOLUTION":
      const solutions = [...state.solutions, action.solution];
      // localStorage.setItem("puzler_solutions", JSON.stringify(solutions));
      return { ...state, solutions };
    default:
      return state;
  }
};

const PuzzleProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  console.log(state.completed);
  useEffect(() => {
    const createCells = (): Cell[] => {
      const cells = [];
      let index = 0;
      for (let i = 0; i < 4; i++) {
        for (let j = 0; j < 4; j++) {
          cells.push({ row: i, column: j, index, selected: false });
          index++;
        }
      }
      return cells;
    };

    const createGrid = () => {
      const cells = createCells();
      dispatch({ type: "INIT_GRID", cells });
    };
    createGrid();
    getHuntData(HUNT_ID).then(
      (data: { numberOfClues: number; id: string; completed: boolean }) => {
        const { numberOfClues, completed } = data;
        dispatch({ type: "INIT_HUNT", numberOfClues, completed });
      }
    );
  }, []);
  useEffect(() => {
    const numCellsSelected = state.cells.reduce(countSelected, 0);
    if (numCellsSelected >= 4) {
      dispatch({ type: "SET_FEEDBACK", feedback: Feedback.Fetching });

      const selectedCells = state.cells.filter((cell: Cell) => cell.selected);
      const indexMap = selectedCells.map((cell: Cell) => cell.index);

      checkCode({
        answers: indexMap,
        huntId: state.huntId,
        clueId: state.clueId,
      }).then(({ data: solved }) => {
        const feedback = solved ? Feedback.Right : Feedback.Wrong;

        dispatch({ type: "SET_FEEDBACK", feedback });
        if (feedback === Feedback.Right) {
          dispatch({ type: "ADD_SOLUTION", solution: indexMap });
        }
      });
    }
  }, [state.cells, state.clueId, state.huntId]);

  useEffect(() => {
    if (!state.numberOfClues) {
      return;
    }
    if (state.solutions.length >= state.numberOfClues) {
      checkWin({
        solutions: JSON.stringify(state.solutions),
        huntId: state.huntId,
      }).then(({ data }) => {
        const { win, message, error } = data;
        alert(message);
        if (win) {
          window.location.href = data.redirect;
        }
        if (error) {
        }
      });
    }
  }, [state.solutions, state.numberOfClues, state.huntId]);
  return (
    <PuzzleContext.Provider value={value}>{children}</PuzzleContext.Provider>
  );
};

export { PuzzleContext, PuzzleProvider };

export const useGrid = () => {
  const context = useContext(PuzzleContext);
  if (context === undefined) {
    throw new Error("must be within its provider: User");
  }

  const { dispatch, state } = context;
  const toggleCell = (row: number, column: number) =>
    dispatch({ type: "TOGGLE_CELL", row, column });

  return { cells: state.cells, toggleCell };
};

export const useGame = () => {
  const context = useContext(PuzzleContext);
  const { addMarkers, hideMarker, loading } = useMarkers();

  if (context === undefined) {
    throw new Error("Puzzle Context error in Game hook");
  }

  const { state, dispatch } = context;

  useEffect(() => {
    if (loading || !state.init) {
      return;
    }
    getCluePosition({ clueId: state.clueId, huntId: state.huntId }).then(
      ({ data }) => {
        const { lat, lng } = data;
        addMarkers([{ position: { lat, lng }, clueId: state.clueId }]);
      }
    );
    //eslint-disable-next-line
  }, [state.clueId, state.huntId, state.init, loading, addMarkers]);

  const clearCells = useCallback(
    () => dispatch({ type: "CLEAR_CELLS" }),
    [dispatch]
  );

  const progress = useCallback(() => {
    dispatch({ type: "PROGRESS" });
  }, [dispatch]);

  return {
    clueId: state.clueId,
    feedback: state.feedback,
    numberOfClues: state.numberOfClues,
    hideMarker,
    clearCells,
    progress,
    completed: state.completed,
  };
};
