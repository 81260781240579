export type Position = {
  lat: number | undefined;
  lng: number | undefined;
};

export type Marker = {
  type: string;
  marker: google.maps.Marker;
};

export type ClueMarker = {
  position: Position;
  clueId: number;
};

export type Cell = {
  row: number;
  column: number;
  index: number;
  selected: boolean;
};

export enum Feedback {
  Idle = "idle",
  Fetching = "fetching",
  Right = "right",
  Wrong = "wrong",
}
