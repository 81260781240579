import { Cell } from "./types";

export const ipfsToHttps = (uri: string) => uri.replace("ipfs", "https");

export const countSelected = (acc: number, cv: Cell) => {
  if (cv.selected) {
    return acc + 1;
  }
  return acc;
};

export const getSelected = (cell: Cell) => cell.selected;

export const getMarker = (selector: string): any => {
  const MAX_TRIES = 500;
  let tries = 0;
  return new Promise((resolve, __) => {
    const pollMarker = (): any => {
      const markerDom = document.querySelector(selector) as HTMLImageElement;
      if (!markerDom && tries < MAX_TRIES) {
        tries++;
        return setTimeout(pollMarker, 50);
      }
      return resolve(markerDom);
    };
    pollMarker();
  });
};

export const fadeIn = (el: HTMLElement) => {
  let o = 0;
  let frame = 0;
  const animate = () => {
    el.style.transform = `scale(${o})`;
    if (o < 1) {
      o += 0.05;
      frame = requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(frame);
    }
  };
  animate();
};

export const delay = (ms: number, callback: Function) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      callback();
      resolve(true);
    }, ms);
  });
};
